/* About.css */
.about-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.about-content {
  flex: 1;
  padding-right: 20px;
}

.about-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.about-description {
  font-size: 16px;
  margin-bottom: 20px;
}

.achievements-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.achievements-list {
  font-size: 16px;
  margin-left: 20px;
}

.community-activities,
.commitment {
  font-size: 16px;
  margin-top: 20px;
}

.about-image {
  flex: 1;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.about-image img {
  max-width: 100%;
  height: auto;
}

/* About.css */
/* ... (existing styles) ... */

.contact-form {
  margin-top: 50px;
}

.contact-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #45a049;
}

.aboutBody{
  margin-top:30px;
  margin-right:30px;
  margin-left:30px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .aboutBody{
    margin:1px;
  }
}