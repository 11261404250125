/* Carousel.css */

.carousel-image {
  height: 80vh;
  object-fit: cover;
  width: 100%;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .carousel-image {
    height: 50vh; /* Adjust the height for mobile screens */
  }
}
