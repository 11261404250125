/* Footer.css */
.footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .social-links a {
    display: inline-block;
    margin: 0 10px;
    transition: transform 0.3s;
  }
  
  .social-links a:hover {
    transform: scale(1.1);
  }
  
  .social-links img {
    width: 30px;
    height: 30px;
  }

  .fontDesignIcon{
    font-size: 36px;
    margin: 0 10px 0 0;
    color: #000000;
    text-decoration: none;
  }

  .fontDesignIcon:hover{
    color: #673ab7; 
  }

  .Facebook{
    color: #3b5998;
  }
  
  .Twitter {
    color: #00aced;
  }

  .Instagram {
    color: #cd486b
  }

  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333; /* Change text color to dark color */
  }
  
  .logo img {
    height: 90px; /* Adjust the height as needed */
    margin-right: 10px;
  }