body {
      margin: 0;
      padding: 0;
    }

    .full-width-image-section {
      width: 100%;
      overflow: hidden;
    }

    .full-width-image-section img {
      width: 100%;
      height:600px;
      object-fit: cover;
      display: block;
    }

/* Projects.css */
.projects-container {
    padding: 20px;
    /* Add your responsive styles here */
  }
  
  /* CommunityActivities.css */
  .community-activities-container {
    padding: 20px;
    /* Add your responsive styles here */
  }
  
  /* Media Queries for Tablets and Mobile */
  @media (max-width: 768px) {
    .projects-container,
    .community-activities-container {
      padding: 10px;
      /* Add responsive styles for smaller screens */
    }
  }
  