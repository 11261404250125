.homeContainer{
  margin: 10px 190px;
}


.homeIcon{
  margin-right:10px;
  color:#dc3545;;
}

.bulletcontent{
  margin-top:40px;
  margin-bottom:40px;
}
.bulletContentBody {
  margin: 0 auto;
}

.bulletContentBody li {
  margin-bottom:5px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .homeContainer{
    margin: 10px;
  }
}

