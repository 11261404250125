/* Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white; /* Change background color to white */
  color: #333; /* Change text color to dark color */
  padding: 10px 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.logo {
  display: flex;
  align-items: center;
}

.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333; /* Change text color to dark color */
}

.logo img {
  height: 90px; /* Adjust the height as needed */
  margin-right: 10px;
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar li {
  margin-right: 20px;
}

.navbar a {
  text-decoration: none;
  color: #333; /* Change text color to dark color */
  font-weight: bold;
}

.navbar a:hover {
  color: #ff9900; /* Change color on hover */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items:space-between;
  }

  .navbar {
    margin-top: 15px;
    justify-content: center;
  }

  .navbar ul {
    flex-direction: column;
    text-align: center;
  }

  .navbar li {
    margin: 10px 0;
  }
}
